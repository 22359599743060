<template>
    <icon
        v-tooltip="statusText"
        class="fa-2x"
        :class="{
            'has-text-danger': outcome === 'failed',
            'has-text-success': outcome === 'passed',
            'has-text-grey': !outcome || outcome === 'checked' || outcome === 'not checked',
        }"
        :icon="status"
        :type="status === 'infinity' ? 'fas' : 'far'"
    />    
</template>
<script>
export default {

    props: {
        outcome: String
    },

    computed: {
        status() {

            if(this.outcome === 'checked') {
                return 'check-square'
            }
            
            if(this.outcome === 'not checked') {
                return 'square'
            }

            if(this.outcome === null || this.outcome === 'na') {
                return 'infinity'
            }
            if(this.outcome === 'passed') {
                return 'thumbs-up'
            }
            if(this.outcome === 'failed') {
                return 'thumbs-down'
            }
            return ''
        },
        
        statusText() {

            if (this.outcome === 'checked') {
                return 'The user checked the box'
            }

            if (this.outcome === 'not checked') {
                 return 'The user did not check the box'
            }

            if(this.outcome === 'passed') {
                return 'This checkpoint has passed'
            }

            if(this.outcome === 'failed') {
                return 'This checkpoint has failed'
            }

            if(this.outcome === 'na') {
                return 'This checkpoint does not effect the outcome of the checklist'
            }

            return 'This checkpoint has not yet been answered'
        }
    }
}
</script>