<template>
<div>
    <columns>
        <column class="is-3">
            <div class="pr-4">
                <columns>
                    <column>
                        <p class="is-flex is-justify-content-space-between">
                            <span>Status</span>
                            <outcome-tag 
                                :status="checklist.status"
                            />
                        </p>
                        <p class="is-flex is-justify-content-space-between">
                            <span>Started</span>
                            <span class="has-text-weight-bold">{{ checklist.started_at | date('L LTS') }}</span>
                        </p>
                        <p class="is-flex is-justify-content-space-between">
                            <span>Completed</span>
                            <span class="has-text-weight-bold">
                                <span v-if="checklist.closed_off_at">
                                    {{ checklist.closed_off_at | date('L LTS') }}
                                </span>
                                <span v-else>--</span>
                            </span>
                        </p>
                        <p v-if="checklist.area && checklist.area.id" class="is-flex is-justify-content-space-between">
                            <span>Area</span>
                            <span class="has-text-weight-bold">{{ checklist.area.name }}</span>
                        </p>
                        <p class="is-flex is-justify-content-space-between">
                            <span>Customer</span>
                            <span class="has-text-weight-bold">{{ checklist.work_order.customer.name }}</span>
                        </p>
                        <p class="is-flex is-justify-content-space-between">
                            <span>Work Order</span>
                            <span class="has-text-weight-bold">
                                <router-link :to="{
                                    name: 'work-order-manager',
                                    params: {
                                        workOrder: checklist.work_order.uuid
                                    }
                                }">
                                    #{{ checklist.work_order.number }}
                                </router-link>
                            </span>
                        </p>
                        <p class="is-flex is-justify-content-space-between">
                            <span>Checkpoints</span>
                            <span class="has-text-weight-bold">{{ checklist.answers.length }}</span>
                        </p>

                        <h5 class="title is-5 mt-4">Closing Remarks</h5>
                        <text-area v-model="notes"></text-area>
                        <submit-button 
                            v-if="!$root.isPassiveUser()"
                            class="is-small is-info is-rounded" 
                            :working="saving" 
                            @submit="saveNotes">
                            Save
                        </submit-button>
                    </column>
                </columns>

                <h5 class="title is-5">Location</h5>

                <div v-if="checklist.geo_location.latitude">
                    <GmapMap
                        :center="{lat: checklist.geo_location.latitude, lng: checklist.geo_location.longitude}"
                        :zoom="16"
                        map-type-id="terrain"
                        style="width: 100%; height: 300px">
                        <GmapMarker ref="myMarker" :position="google && new google.maps.LatLng(checklist.geo_location.latitude, checklist.geo_location.longitude)" />
                    </GmapMap>
                </div>

                <p class="has-text-grey" v-else>
                    <em>There is no location available for this checklist.</em>
                </p>
            </div>
        </column>
        <column>
            
            <columns>
                <column>
                    <h5 class="title is-5">Checkpoints <span class="has-text-weight-light">({{ checklist.answers.length }})</span></h5>
                </column>
                <column class="is-narrow">
                    <action-button class="is-small is-rounded" @click="expanded = !expanded">
                        {{ expanded ? 'Collapse All' : 'Expand All' }}
                    </action-button>
                </column>
            </columns>

            <div class="index-rows">
                <div class="box is-marginless" v-for="(answer, index) in checklist.answers" :key="answer.id">
                    <answer-row 
                        v-bind="{ answer, index, expanded }"
                    />
                </div>
            </div>
        </column>
    </columns>
</div>
</template>
<script>
import { mapGetters } from 'vuex'
import AnswerRow from '../partials/AnswerRow.vue'
import { getGoogleMapsAPI } from 'gmap-vue'
import { inspection as backend } from '@/api'

export default {

    components: {
        AnswerRow
    },

    data: () => ({
        markers: [],
        expanded: false,
        notes: '',
        saving: false
    }),

    mounted() {
        this.notes = this.checklist.notes
    },

    methods: {
        mapOutcome(answer) {
            return answer.value
        },
        saveNotes() {
            this.saving = true
            backend.updateNotes({
                checklistId: this.checklist.uuid,
                notes: this.notes
            }, () => {
                this.$toast.success('Saved')
                this.saving = false
            }, () => this.saving = false)
        }
    },

    computed: {
        ...mapGetters('inspection', [
            'checklist'
        ]),
        google: getGoogleMapsAPI,
    }

}
</script>