<template>
    <columns class="mt-2">
        <column>
            <p>
                <em>
                    <component 
                        :is="checkpointType + '-outcome'"
                        :answer="answer.value"
                        :builder="answer.checkpoint.handler_payload"
                    />
                </em>
            </p>
        </column>
    </columns>
</template>
<script>
export default {

    props: {
        answer: {
            type: Object,
            default: () => ({})
        }
    },

    computed: {
        checkpointType() {
            return this.answer.checkpoint.type.builder
        }
    }
}
</script>