<template>
    <columns>
        <column>
            <div class="is-hoverable" @click="isOpen = !isOpen">
                <h4 class="title is-5 mb-0">
                    <span class="has-text-weight-bold">{{ index + 1 }}.</span> {{ answer.checkpoint.instruction }}
                </h4>
                <small 
                    v-if="answer.checkpoint.additional_information" 
                    class="is-flex is-align-items-center has-text-grey">
                    {{ answer.checkpoint.additional_information }}
                </small>
            </div>
            <div v-if="isOpen || expanded">

                <checkpoint-answer-view 
                    :answer="answer"
                />
                
                <columns>
                    <column>
                        <p class="has-text-weight-bold has-text-info">Notes</p>

                        <div v-if="answer.notes" class="has-text-grey" v-html="answer.notes"></div>

                        <p v-else>
                            <em class="has-text-grey">
                                Notes have not been provided for this checkpoint.
                            </em>
                        </p>
                    </column>
                </columns>

                <columns>
                    <column>
                        <p class="has-text-weight-bold has-text-info">Images <span class="has-text-weight-light">({{ answer.images.length }})</span></p>

                        <p v-if="!answer.images.length">
                            <em class="has-text-grey">
                                Images have not been captured on this checkpoint.
                            </em>
                        </p>

                        <columns class="is-multiline">
                            <column @click="openPhoto(image)" v-for="image in answer.images" :key="image.id" class="is-narrow is-hoverable">
                                <div class="answer-thumbnail"
                                    :style="{ 'background-image': `url(${image.image_url})` }">
                                </div>
                            </column>
                        </columns>
                    </column>
                </columns>

                <columns>
                    <column>
                        <p class="has-text-weight-bold has-text-info mb-3">Videos <span class="has-text-weight-light">({{ answer.videos.length }})</span></p>
                        <columns class="is-multiline">
                            <column v-for="video in answer.videos" :key="video.id" class="is-3">
                                <video width="320" height="240" controls>
                                    <source :src="video.video_url" type="video/mp4">
                                    Your browser does not support the video tag.
                                </video>
                            </column>
                        </columns>
                        <p>
                            <small class="has-text-grey" v-if="!answer.videos.length">
                                No videos have been captured on this checkpoint.
                            </small>
                        </p>
                    </column>
                </columns>
            </div>
        </column>
        <column class="is-1 is-flex is-align-items-start">
            <span class="is-flex is-align-items-center is-justify-content-end" v-tooltip="'# of videos captured on this checklist'">
                <icon icon="film" class="has-text-grey index-icon" />
                <span class="ml-3">{{ answer.videos.length }}</span>
            </span>
        </column>
        <column class="is-1 is-flex is-align-items-start">
            <span class="is-flex is-align-items-center is-justify-content-end" v-tooltip="'# of images captured on this checklist'">
                <icon icon="images" type="far" class="has-text-grey index-icon" />
                <span class="ml-3">{{ answer.images.length }}</span>
            </span>
        </column>
        <column class="is-narrow is-flex is-align-items-start">
            <outcome-icon :outcome="answer.outcome"/>
        </column>
    </columns>
</template>
<script>
import CheckpointAnswerView from './CheckpointAnswerView.vue'
import OutcomeIcon from './OutcomeIcon.vue'
import { photoEvidence } from '@/modals'

export default {

    components: {
        CheckpointAnswerView,
        OutcomeIcon
    },

    props: {
        expanded: Boolean,
        index: Number,
        answer: {
            type: Object,
            default: () => ({})
        }
    },

    data: () => ({
        isOpen: false
    }),

    methods: {
        openPhoto(image) {
            photoEvidence(image)
        }
    }
}
</script>